import React from 'react';

function DownloadButton() {
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = 'https://drive.google.com/uc?export=download&id=1Z7I3MHMBeVcIezOOcB8fVO-WpvQeBgai'; // Replace with your actual filename
    link.download = 'Luis_Antunes_Resume.pdf'; // Set the desired download filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <button onClick={handleDownload} className="download-button">
      Download Resume
    </button>
  );
}

export default DownloadButton;