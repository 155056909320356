import React from 'react'
import '../projects.css'
import './projectPages.css'

const AccentTalk = () => {
  return (
    <section id='projects' className='projectPages'>
      <h1 className="projectsTitle">Accent-Invariant ASR</h1>
      <h2 className="projectsSubtitle">accentalk, ai-powered, accent-proof</h2>
      <p className="projectPagesDesc">In an increasingly interconnected world, where accents enrich our linguistic tapestry, 
        AccenTalk emerges as a testament to the power of deep learning in fostering clearer communication. 
        Trained on a diverse dataset of approximately 1800 spoken English samples encompassing a wide array of accents, 
        AccenTalk leverages the PyTorch library and LSTM networks to transcend the limitations posed by accents in traditional
        speech-to-text systems.</p>
      <p className="projectPagesDesc">Features:</p>
      <ul className='projectBulletPoints'>
        <li className="projectPagesDesc">Designed to accurately transcribe speech from individuals with diverse accents, promoting
          inclusivity and accessibility</li>
        <li className="projectPagesDesc">Trained on a rich dataset representing 177 different native and non-native English accents, 
          ensuring robustness and adaptability</li>
        <li className="projectPagesDesc">Built with PyTorch for streamlined development and optimized performance</li>
        <li className="projectPagesDesc">Use of MFCCs for feature extraction enables the model to capture the essential characteristics 
          of speech signals, improving recognition accuracy</li>
      </ul>
      <iframe 
        src="https://drive.google.com/file/d/1rDXfMs52pKV-hlyUiQANmRFado0kyYJe/preview?embedded=true&rm=minimal"
        width="85%" 
        height="600" 
        allow="autoplay" 
        title="AccentTalk Project PDF Viewer" 
        style={{ border: 'none' }} 
      ></iframe>
    </section>
  )
}

export default AccentTalk