import React from 'react'
import './projects.css'
import project1 from '../../assets/ProjectThumbnails/mapping_software.png'
import project2 from '../../assets/ProjectThumbnails/accent_talk.png'
import project3 from '../../assets/ProjectThumbnails/grocery_flow.png'
import project4 from '../../assets/ProjectThumbnails/home_lab.png'
import project5 from '../../assets/ProjectThumbnails/enterprise_rag.png'
import { useNavigate } from 'react-router-dom'

const Projects = () => {
    const navigate = useNavigate();

    // Handler function for image click
    const navigateToProject = (projectName) => {
        navigate(`/projects/${projectName}`);
    };

    return (
      <section id='projects'>
          <h1 className="projectsTitle">Projects</h1>
          <h2 className="projectsDesc">My expanding mix of professional and personal projects.</h2>
          <div className="projectImages">
              <div className="projectContainer" onClick={() => navigateToProject('mapping-software')}>
                  <img src={project1} alt="Geographic Information System" className="projectsImg" />
                  <div className="overlay">
                      <div className="overlayText">Geographic Information System</div>
                  </div>
              </div>
              <div className="projectContainer" onClick={() => navigateToProject('accent-talk')}>
                  <img src={project2} alt="Accent-Invariant ASR" className="projectsImg" />
                  <div className="overlay">
                      <div className="overlayText">Accent-Invariant ASR</div>
                  </div>
              </div>
              <div className="projectContainer" onClick={() => navigateToProject('grocery-flow')}>
                  <img src={project3} alt="Grocery Automator" className="projectsImg" />
                  <div className="overlay">
                      <div className="overlayText">Grocery Automator</div>
                  </div>
              </div>
              <div className="projectContainer" onClick={() => navigateToProject('home-lab')}>
                  <img src={project4} alt="Home Lab" className="projectsImg" />
                  <div className="overlay">
                      <div className="overlayText">Home Lab</div>
                  </div>
              </div>
              <div className="projectContainer" onClick={() => navigateToProject('enterprise-rag')}>
                  <img src={project5} alt="Enterprise RAG" className="projectsImg" />
                  <div className="overlay">
                      <div className="overlayText">Enterprise RAG</div>
                  </div>
              </div>
              {/* Add more project containers as needed */}
          </div>
      </section>
  );
}

export default Projects