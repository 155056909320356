import React from 'react'
import '../projects.css'
import './projectPages.css'

const HomeLab = () => {
  return (
    <section id='projects' className='projectPages'>
        <h1 className="projectsTitle">Home Lab</h1>
        <h2 className="projectsSubtitle">my home's central hub</h2>
        <p className="projectPagesDesc">A streamlined and powerful server designed to centralize control and access to a diverse ecosystem 
          of home lab applications and services.</p>
        <p className="projectPagesDesc">Built upon Unraid and Docker containers, this project offers a comprehensive suite of functionalities 
          including media management, file downloading, and cloud integration.</p>
        <p className="projectPagesDesc">Features:</p>
        <ul className='projectBulletPoints'>
          <li className="projectPagesDesc">Homarr for a user-friendly interface serving as the central hub for all applications</li>
          <li className="projectPagesDesc">Sonarr, Radarr, and Readarr efficiently organize TV shows, movies, books, and audiobooks, providing 
            seamless access to your collection</li>
          <li className="projectPagesDesc">Jellyfin organizes and streams media collections, providing access from anywhere</li>
          <li className="projectPagesDesc">Adguard Home acts as a custom DNS resolver, blocking trackers and malicious queries, and enhancing
            online privacy and security</li>
          <li className="projectPagesDesc">WireGuard VPN for secure remote access from anywhere in the world</li>
          <li className="projectPagesDesc">Immich serves as a high performance self-hosted photo and video management solution</li>
        </ul>
    </section>
  )
}

export default HomeLab