import React from 'react';
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import NavBar from "./components/NavBar/navbar";
import Home from "./pages/Home/home";
import Resume from './pages/Resume/resume';
import Projects from './pages/Projects/projects';
import Contacts from './pages/Contacts/contacts';
import MappingSoftware from './pages/Projects/ProjectPages/mappingSoftware';
import AccentTalk from './pages/Projects/ProjectPages/accentTalk';
import GroceryFlow from './pages/Projects/ProjectPages/groceryFlow';
import HomeLab from './pages/Projects/ProjectPages/homeLab';
import HyperlocalLLM from './pages/Projects/ProjectPages/hyperlocalLLM';
import Footer from './components/Footer/footer';

function App() {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/resume" element={<Resume />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/projects/mapping-software" element={<MappingSoftware />} />
        <Route path="/projects/accent-talk" element={<AccentTalk />} />
        <Route path="/projects/grocery-flow" element={<GroceryFlow />} />
        <Route path="/projects/home-lab" element={<HomeLab />} />
        <Route path="/projects/enterprise-rag" element={<HyperlocalLLM />} />
        <Route path="/contacts" element={<Contacts />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
