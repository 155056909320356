import React, { useEffect, useRef, useState } from 'react';
import './contacts.css';
import GithubIcon from '../../assets/SocialMediaIcons/github-icon.png';
import LinkedinIcon from '../../assets/SocialMediaIcons/linkedinLogo.png';
import emailjs from '@emailjs/browser';

const Contacts = () => {
  const form = useRef();
  const textareaRef = useRef(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [message, setMessage] = useState("");
  const [charCount, setcharCount] = useState(0);
  const charLimit = 1000; // Set the word limit here

  useEffect(() => {
    const textarea = textareaRef.current;
    const adjustHeight = () => {
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';
    };

    if (textarea) {
      textarea.addEventListener('input', adjustHeight);
      adjustHeight();
    }

    return () => {
      if (textarea) {
        textarea.removeEventListener('input', adjustHeight);
      }
    };
  }, []);

  const handleMessageChange = (e) => {
    const text = e.target.value;
    if (text.length <= charLimit) {
      setMessage(text);
      setcharCount(text.length);
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();

    const submitButton = document.getElementById('submitButton');
    submitButton.disabled = true;

    emailjs.sendForm('service_zv5pdpg', 'template_hh1vg9i', form.current, 'pOOVh5VMpnUU8tpu_')
      .then((result) => {
          console.log(result.text);
          setSuccessMessage("Form successfully submitted!");
          setMessage("");
          setcharCount(0);
          e.target.reset();
          submitButton.disabled = false;
      }, (error) => {
          console.log(error.text);
          setSuccessMessage("Failed to submit form!");
          submitButton.disabled = false;
      });
  };

  return (
    <section id='contactPage'>
        <h1 className="contactTitle">Contact Me</h1>
        <span className="contactDesc">I'm always eager to explore new ideas and collaborations. 
          Please don't hesitate to reach out using the contact form below or connect with me on social media.</span>
        <form className="contactForm" ref={form} onSubmit={sendEmail}>
            <input type="text" className="name" placeholder='Full Name' name='user_name'/>
            <input type="email" className="email" placeholder='Email Address' name='user_email'/>
            <div className="textareaContainer">
              <div className="textareaWrapper">
                <textarea
                  ref={textareaRef}
                  className="message"
                  name="message"
                  rows="5"
                  placeholder='Message'
                  value={message}
                  onChange={handleMessageChange}
                ></textarea>
                <div className="charCount"> {charCount}/{charLimit}</div>
              </div>
            </div>
            <p className="successMsg" id="successMessage">{successMessage}</p>
            <button id='submitButton' type='submit' className="submitBtn">Submit</button>
            <div className="links">
                <a href="https://www.linkedin.com/in/luis-antunes/" target='_blank' rel="noopener noreferrer">
                  <img src={LinkedinIcon} alt="LinkedIn" className="link" />
                </a>
                <a href="https://github.com/luis-antunes" target='_blank' rel="noopener noreferrer">
                  <img src={GithubIcon} alt="GitHub" className="link" />
                </a>
            </div>
        </form>
    </section>
  );
};

export default Contacts;