import React from 'react'
import './resume.css'
import DownloadButton from './downloadButton';

const Resume = () => {
  return (
    <div className="resume-container">
      <header className="header">
        <div>
          <h1 className="resume-name">Luis Carlos Antunes</h1>
          <h3 className="resume-job-title">Software Engineer</h3>
        </div>
        <DownloadButton></DownloadButton>
      </header>

      <section className="education">
        <h3>Education</h3>
        <div className="education-item">
          <div className='subtitle'>
            <h4>Bachelor of Applied Science in Computer Engineering</h4>
            <p>Expected May 2025</p>
          </div>
          <div>
            <p>📍 University of Toronto</p>
          </div>
        </div>
      </section>

      <section className="skills">
        <h3>Skills</h3>
        <div className="skill-item">
          <p>Programming: Python, C, C++, JavaScript, HTML, CSS</p>
          <p>Frameworks: React, PyTorch, FastAPI, Kubernetes, Docker Compose, NumPy, Pandas, Matplotlib, Git</p>
          <p>Languages: English, Portuguese</p>
        </div>
      </section>

      <section className="experience">
        <h3>Experience</h3>
        <div className="experience-item">
          <div className='subtitle'>
            <h4>Software Engineer Intern, <span className="company-name">Compugen Inc.</span></h4>
            <p>Sept 2023 — Aug 2024</p>
          </div>
          <div>
            <p>📍 Toronto, Canada</p>
          </div>
          <div>
            <ul className='resume-bullet-points'>
              <li>Built a full-stack distributed AI platform from scratch: FastAPI backend, React frontend, Qdrant vector 
                database, Redis for user data, and OAuth 2.0 authentication, serving 50+ users with sub-30s responses</li>
              <li>Delivered a 90% accurate RAG-based HR query system through PyTorch optimizations</li>
              <li>Maintained version control through Git and Azure DevOps, assisting in collaboration and documentation</li>
              <li>Presented AI Lab’s achievements at National Sales Conference, communicating complex technical concepts
              to a non-technical audience</li>
            </ul>
          </div>
        </div>

        <div className="experience-item">
          <div className='subtitle'>
            <h4>IT Consulting Intern, <span className="company-name">Compugen Inc.</span></h4>
            <p>May 2023 — Aug 2023</p>
          </div>
          <div>
            <p>📍 Toronto, Canada</p>
          </div>
          <div>
            <ul className='resume-bullet-points'>
              <li>Developed Python script for email address reconciliation, reducing mailbox migration time by 75%, saving
              120 hours for a 5-consultant team</li>
              <li>Presented C-suite AI strategy, resulting in a 60% consultant efficiency increase and secured a 1-year
              internship</li>
              <li>Conducted M365 migration risk assessment for a major financial client, developing and implementing
              mitigation strategies to accelerate the process by 20%</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="projects">
        <h3>Projects</h3>
        <div className="projects-item">
          <div className='subtitle'>
            <h4>Home Lab Project</h4>
          </div>
          <div>
            <ul className='resume-bullet-points'>
              <li>Designed and implemented a multi-purpose home server leveraging Unraid and Docker containers,
              providing VPN (WireGuard), media streaming (Plex), and photo storage (Immich)</li>
              <li>Configured DNS resolver to block trackers, enhancing privacy and blocking 99% of malicious queries</li>
            </ul>
          </div>
        </div>
      
        <div className="projects-item">
          <div className='subtitle'>
            <h4>AccenTalk</h4>
          </div>
          <div>
            <ul className='resume-bullet-points'>
              <li>Developed an RNN-based deep learning model for improved accent recognition, using PyTorch and
              bi-directional LSTMs on a diverse dataset of 1800+ spoken English samples</li>
              <li>Iteratively improved a baseline speech-to-text model, incorporating techniques like CTC loss and dropout
              regularization to enhance performance on non-native accents</li>
            </ul>
          </div>
        </div>

        <div className="projects-item">
          <div className='subtitle'>
            <h4>GroceryFlow</h4>
          </div>
          <div>
            <ul className='resume-bullet-points'>
              <li>Developed grocery shopping automation using Python, saving users an average of 4 hours per month</li>
              <li>Implemented a GUI using PySimpleGui, enabling efficient product search, filtering, and streamlined
              checkout</li>
              <li>Automated discount detection during checkout using web scraping and Stripe’s REST API</li>
            </ul>
          </div>
        </div>

        <div className="projects-item">
          <div className='subtitle'>
            <h4>OpticMap</h4>
          </div>
          <div>
            <ul className='resume-bullet-points'>
              <li>Achieved a 4x increase in rendering speed (from 15 FPS to 60 FPS) for dense maps through Level of Detail
              (LOD) optimization using the GTK library</li>
              <li>Reduced route calculation time by 50% through the implementation of A* pathfinding</li>
              <li>Enabled accurate location discovery even with spelling errors using BK-Tree autocomplete</li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Resume;