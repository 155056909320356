import React from 'react';
import './home.css'
import {Link} from 'react-router-dom'
import profilePicture from '../../assets/PersonalPictures/IMG_7199.png'

const Home = () => {
    return (
        <section id='Home'>
            <div className="homeContent">
                <span className="hello">Hello,</span>
                <span className="introText">I'm <span className="introName">Luis Antunes</span> <br />Computer Engineer</span>
                <p className="introParagraph"> Bridging cutting-edge technology with real-world applications to craft solutions that elevate human lives. People-focused, citizen of the world, and tinkerer.</p>
                <Link to="https://calendly.com/lcantunes/1-1-to-ask-me-about-anything" className="btn">
                    Book a meeting
                </Link>
            </div>
            <img src={profilePicture} alt="profile" className="bg" />
        </section>
    )
}

export default Home;