import React from 'react'
import '../projects.css'
import './projectPages.css'
import CartExampleImg from '../../../assets/GroceryFlow/cart-example-gf.png'
import SearchExampleImg from '../../../assets/GroceryFlow/search-example-gf.png'

const GroceryFlow = () => {
  return (
    <section id='projects' className='projectPages'>
      <h1 className="projectsTitle">Grocery Automator</h1>
      <h2 className="projectsSubtitle">groceryflow, streamline your groceries</h2>
      <p className="projectPagesDesc">Juggling the demands of university life, grocery shopping became a time-intensive task, 
        prompting me to devise a personal automation solution for this chore.</p>
        <p className="projectPagesDesc">Constructed using Python, I harnessed the capabilities of the requests and PySimpleGui
        modules to create this application.</p>
      <p className="projectPagesDesc">Features:</p>
      <ul className='projectBulletPoints'>
        <li className="projectPagesDesc">Streamlined auto-login and seamless account creation</li>
        <li className="projectPagesDesc">Simplified search functionality for efficient product discovery</li>
        <li className="projectPagesDesc">Smart background caching of in-stock items (using threads), optimizing loading times and
            reducing waiting periods</li>
        <li className="projectPagesDesc">Automatically finds discounts and applies them, optimizing savings</li>
          <li className="projectPagesDesc">User-friendly, one-click checkout process</li>
      </ul>

      <figure>
          <img className="projectPagesImg" src={CartExampleImg} alt='A grid of grocery items with product details and "Add to Cart" buttons.' />
          <figcaption className="projectPagesImgCaption">Figure 1. Simple search page displaying exclusively cached in-stock items.</figcaption>
        </figure>
        <figure>
          <img className="projectPagesImg" src={SearchExampleImg} alt="A shopping cart summary showing one item with a discounted total price and checkout options." />
          <figcaption className="projectPagesImgCaption">Figure 2. User-friendly, one-click checkout process.</figcaption>
        </figure>
    </section>
  )
}

export default GroceryFlow