import React from 'react'
import '../projects.css'
import './projectPages.css'
import InstructionExampleImg from '../../../assets/OpticMap/instructions-example.gif'
import PathClickExampleImg from '../../../assets/OpticMap/path-click-example.gif'
import SearchExampleImg from '../../../assets/OpticMap/search-example.gif'

const MappingSoftware = () => {
  return (
    <section id='projects' className='projectPages'>
        <h1 className="projectsTitle">Geographic Information System</h1>
        <h2 className="projectsSubtitle">opticmap, an accessible map</h2>
        <p className="projectPagesDesc">A straightforward, sleek, and adaptable Geographic Information System (GIS) with accessibility as a top priority.</p>
        <p className="projectPagesDesc">For my 2nd-year Software Communication & Design course, I built this application using C++ and the EZGL library (a handy GTK wrapper).</p>
        <p className="projectPagesDesc">Features:</p>
        <ul className='projectBulletPoints'>
          <li className="projectPagesDesc">A snappy autocomplete search function that’s all about intersections and street names.</li>
          <li className="projectPagesDesc">Nifty pathfinding for those moments when you need to figure out your way between two intersections or two streets.</li>
          <li className="projectPagesDesc">Say hello to a fluid interface running at 30-60 FPS – even when you’re exploring the busiest maps.</li>
          <li className="projectPagesDesc">An info panel for intersections, conveniently tucked in the corner of your screen.</li>
          <li className="projectPagesDesc">Toggle-able points of interest.</li>
          <li className="projectPagesDesc">Smooth zoom-in action on the searched location or intersection.</li>
        </ul>
        
        <figure>
          <img className="projectPagesImg" src={InstructionExampleImg} alt="Screenshot showing the instruction panel of the map application" />
          <figcaption className="projectPagesImgCaption">Figure 1. Guiding instruction panel for intuitive map usage.</figcaption>
        </figure>
        <figure>
          <img className="projectPagesImg" src={PathClickExampleImg} alt="Illustration of selecting a route by clicking two points on the map" />
          <figcaption className="projectPagesImgCaption">Figure 2. Route between any two map locations accessible with a simple click.</figcaption>
        </figure>
        <figure>
          <img className="projectPagesImg" src={SearchExampleImg} alt="Example of using the search feature to find streets and intersections in the map application" />
          <figcaption className="projectPagesImgCaption">Figure 3. Search capability compatible with streets and intersections.</figcaption>
        </figure>
    </section>
  )
}

export default MappingSoftware