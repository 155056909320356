import React from 'react'
import '../projects.css'
import './projectPages.css'
import PromptExampleImg from '../../../assets/HyperLocalLLM/prompt_example.png'
import LoginExampleImg from '../../../assets/HyperLocalLLM/login_example.png'

const HyperlocalLLM = () => {
  return (
    <section id='projects' className='projectPages'>
        <h1 className="projectsTitle">Enterprise RAG</h1>
        <h2 className="projectsSubtitle">hyperlocalllm, privacy focused on-prem RAG</h2>
        <p className="projectPagesDesc">HyperLocalLLM is a dedicated on-premise Language Learning Machine (LLM) designed for
          enterprises seeking localized language processing solutions. By deploying HyperLocalLLM within your environment,
          you harness the power of advanced language models tailored to your regional and organizational needs, ensuring
          high-precision natural language understanding and generation. </p>
        <p className="projectPagesDisclaimer">DISCLAIMER: This project was developed during my internship at Compugen Inc. from 
          September 2023 to August 2024. All intellectual property rights associated with this solution belong exclusively to Compugen Inc. </p>
        <p className="projectPagesDesc">Features:</p>
        <ul className='projectBulletPoints'>
          <li className="projectPagesDesc">Customized language models attuned to local dialects, terminologies, and context</li>
          <li className="projectPagesDesc">Retain full control over your data and language processing tasks within your infrastructure</li>
          <li className="projectPagesDesc">Whether a startup or a large corporation, HyperLocalLLM scales to meet your needs</li>
          <li className="projectPagesDesc">Seamless integration with existing systems and workflows, facilitating a plug-and-play
            deployment</li>
        </ul>
        <figure>
          <img className="projectPagesImg" src={PromptExampleImg} alt="Screenshot showing the chatbox panel of the application" />
          <figcaption className="projectPagesImgCaption">Figure 1. Chatbot interface with sample questions and a user account section.</figcaption>
        </figure>
        <figure>
          <img className="projectPagesImg" src={LoginExampleImg} alt="Screenshot of the login panel of the application" />
          <figcaption className="projectPagesImgCaption">Figure 2. User authentication interface.</figcaption>
        </figure>
    </section>
  )
}

export default HyperlocalLLM