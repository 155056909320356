import React, { useState } from 'react';
import './navbar.css';
import { NavLink } from 'react-router-dom';
import burgerMenuLogo from '../../assets/General/burgerMenuIcon.png';

const NavBar = () => {
    const [showMenu, setShowMenu] = useState(false);

    // Function to dynamically add 'active' class
    const getClassName = ({ isActive }) => 
        isActive ? 'desktopMenuListItem active' : 'desktopMenuListItem';

    return (
        <nav className='navbar'>
            <div className='desktopMenu'>
                <NavLink to="/" className={getClassName}>Home</NavLink>
                <NavLink to="/resume" className={getClassName}>Resume</NavLink>
                <NavLink to="/projects" className={getClassName}>Projects</NavLink>
                <NavLink to="/contacts" className={getClassName}>Contacts</NavLink>
            </div>

            <img src={burgerMenuLogo} alt="Menu" className="burgerMenu" onClick={() => setShowMenu(!showMenu)} />
            <div className='mobileMenu' style={{ display: showMenu ? 'flex' : 'none' }}>
                <NavLink to="/" className={getClassName} onClick={() => setShowMenu(false)}>Home</NavLink>
                <NavLink to="/resume" className={getClassName} onClick={() => setShowMenu(false)}>Resume</NavLink>
                <NavLink to="/projects" className={getClassName} onClick={() => setShowMenu(false)}>Projects</NavLink>
                <NavLink to="/contacts" className={getClassName} onClick={() => setShowMenu(false)}>Contacts</NavLink>
            </div>
        </nav>
    );
}

export default NavBar;
